import React, { FC } from "react";
import classNames from "classnames";
import { starRatingContainer } from "./styles";
import { HalfStarSVG, StarSVG } from "@epignosis_llc/gnosis/icons";

type StarRatingProps = {
  count: number;
  readonly?: boolean;
  rating?: number[];
  iconHeight?: number;
  hideButtonMaxWidth?: boolean;
  onRate?: (rating: number) => void;
};

const StarRating: FC<StarRatingProps> = ({
  count,
  readonly = false,
  rating = [1, 2, 3, 4, 5],
  iconHeight = 32,
  hideButtonMaxWidth = false,
  onRate,
}) => {
  const isHalfStar = count % 1 !== 0;

  const handleRate = (rating: number): void => {
    if (!readonly && onRate) {
      onRate(rating);
    }
  };

  const classNamesContainer = classNames({
    "is-readonly": readonly,
  });

  const buttonClassNames = (rating: number): string =>
    classNames({
      active: isHalfStar ? rating <= Math.ceil(count) : rating <= count,
      readonly: readonly,
      "hide-button-max-width": hideButtonMaxWidth,
    });

  return (
    <div css={starRatingContainer} className={classNamesContainer}>
      {rating.map((rating) => (
        <button
          type="button"
          key={rating}
          className={buttonClassNames(rating)}
          onClick={(): void => handleRate(rating)}
        >
          {/* Only show half star when count is float */}
          {Math.ceil(count) === rating && isHalfStar ? (
            <HalfStarSVG height={iconHeight} className="half-star" />
          ) : (
            <StarSVG className="star" height={iconHeight} />
          )}
        </button>
      ))}
    </div>
  );
};

export default StarRating;
