// Packages or third-party libraries
import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { CourseExitSVG, AssignmentPassedAnswer } from "@epignosis_llc/gnosis/icons";
import { useNavigate } from "react-router-dom";
import { SerializedStyles } from "@emotion/react";

// Styles
import { courseHeaderStyles } from "../../styles";

// Components
import CoverImage from "../../CoverImage";
import InfoTag from "../../InfoTag";
import IntoVideo from "../../IntoVideo";
import TitleContainer from "../../TitleContainer";
import CourseButton from "./CourseButton";
import Restrictions from "./Restrictions";

// Utils, hooks
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore, useUnitStore } from "@stores";

// Other imports
import { Course, CourseCategory } from "types/entities";
import { getCourseCategoryText } from "../../helpers";

type CourseHeaderProps = {
  course: Course;
  categoryTree: CourseCategory[];
  backLink?: string;
};

const CourseStoreCourseHeader: FC<CourseHeaderProps> = ({ course, categoryTree, backLink }) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const { isCourseHeaderSticky } = useUnitStore();
  const { domainSettings } = useConfigurationStore();
  const { features_allowed_in_plan, is_in_trial: isTrialEnabled } = domainSettings ?? {};
  const { talent_library: isTalentLibraryEnabled } = features_allowed_in_plan ?? {};

  const { name, category, code, cover_image, intro_video, id: courseId, mapped_course_id } = course;
  const { categoryText, allCategoryParent } = getCourseCategoryText({ category, categoryTree });
  const showAdditionalInfo = !isCourseHeaderSticky;

  const isCourseAcquired = Boolean(mapped_course_id);

  return (
    <div
      css={(theme): SerializedStyles =>
        courseHeaderStyles(theme, { isSticky: isCourseHeaderSticky })
      }
    >
      {backLink && (
        <Button
          noGutters
          color="primaryLight"
          className="back-button"
          onClick={(): void => navigate(backLink)}
        >
          <CourseExitSVG height={32} />
        </Button>
      )}

      <div className="start-container">
        <TitleContainer
          category={category}
          categoryText={categoryText}
          allCategoryParent={allCategoryParent}
          name={name}
          code={code}
        />

        {showAdditionalInfo && (
          <div className="additional-info-container">
            {isCourseAcquired && (
              <InfoTag className="course-acquired-tag">
                <AssignmentPassedAnswer height={16} />
                {t("courseCatalog.youHaveCourse")}
              </InfoTag>
            )}
            <div className="restrictions-wrapper">
              {!isTalentLibraryEnabled && <Restrictions />}
            </div>
          </div>
        )}
      </div>

      <div className="end-container">
        <CoverImage coverImage={cover_image} courseName={name} />
        <CourseButton
          courseId={courseId.toString()}
          mappedCourseId={mapped_course_id?.toString()}
          isCourseAcquired={isCourseAcquired}
          isTalentLibraryEnabled={isTalentLibraryEnabled}
          isTrialEnabled={isTrialEnabled}
        />
        <IntoVideo introVideo={intro_video} name={name} />
      </div>
    </div>
  );
};

export default CourseStoreCourseHeader;
