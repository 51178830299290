import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { useDebounceFn } from "ahooks";
import { ratingContainer } from "./styles";
import { Course } from "types/entities";
import StarRating from "@components/StarRating/StarRating";
import { usePostCourseRating, useResetCourseRating } from "@hooks/api/courses";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";

export type RatingProps = {
  course: Course;
  isMobileView?: boolean;
};

const AverageRating: FC<{ averageRating: number; isUserEnrolled: boolean }> = ({
  averageRating,
  isUserEnrolled,
}) => {
  const { t } = useApplyTranslations();
  const text =
    averageRating || isUserEnrolled
      ? t("courseCatalog.averageRating")
      : t("courseCatalog.noUsersRatings");

  return (
    <div className="average-rating">
      <Text fontSize="sm" weight="700">
        {averageRating}
      </Text>
      <Text fontSize="sm">({text})</Text>
    </div>
  );
};

const Rating: FC<RatingProps> = ({ course, isMobileView = false }) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const allowRating = Boolean(domainSettings?.course_rating);

  const { id, enrolled, rating } = course;
  const isUserEnrolled = Boolean(enrolled);
  const averageRating = rating?.average ?? 0;
  const userRating = rating?.user;

  const { mutate: postRating, isLoading: postRatingLoading } = usePostCourseRating(id);
  const { mutate: resetRating, isLoading: resetRatingLoading } = useResetCourseRating(id);
  const { run } = useDebounceFn((rating: number) => postRating(rating), { wait: 500 });
  const isLoading = postRatingLoading || resetRatingLoading;
  const userRatingText = userRating ? t("general.yourRate") : t("general.rateIt");

  if (!allowRating) return null;

  if (!isUserEnrolled) {
    // show only the average rating
    return (
      <div css={ratingContainer} className="average-rating-wrapper">
        <StarRating count={averageRating} rating={[1]} readonly />
        <AverageRating averageRating={averageRating} isUserEnrolled={isUserEnrolled} />
      </div>
    );
  }

  return (
    <div css={ratingContainer}>
      {isMobileView && (
        <StarRating
          count={userRating ?? 0}
          onRate={run}
          readonly={isLoading}
          iconHeight={46}
          hideButtonMaxWidth
        />
      )}

      <div className="rating-info-wrapper">
        {!isMobileView && <StarRating count={userRating ?? 0} onRate={run} readonly={isLoading} />}
        <Text fontSize="sm">{userRatingText.toLowerCase()}</Text>
        <Text fontSize="sm" className="upper-dot"></Text>
        <AverageRating averageRating={averageRating} isUserEnrolled={isUserEnrolled} />

        {userRating && (
          <>
            <Text fontSize="sm" className="upper-dot"></Text>
            <Button
              variant="link"
              color={!isMobileView ? "primaryLight" : "primary"}
              className="reset-btn"
              noGutters
              underlined
              onClick={resetRating}
            >
              {t("general.reset")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Rating;
